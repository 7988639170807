import React from 'react';
import { graphql } from 'gatsby';

import {
	Layout,
	Hero,
	Container,
	Margins,
	Background,
	TextCard,
	Grid,
	Spacer,
	NumberedContent,
	Map,
} from '../components';

const getOdd = (_, i) => i % 2;
const getEven = (_, i) => !(i % 2);

const renderRow = (row) => (
	<Margins>
		{row.map((paragraph, index) => (
			<p key={index} className="f-large color-black-light">
				{paragraph.cooperations_description}
			</p>
		))}
	</Margins>
);

const AboutPage = ({
	pageContext,
	data: {
		wordpressPage: { acf: translations },
	},
}) => (
	<Layout
		title={translations.seo_title}
		description={translations.seo_description}
		image={translations.seo_cover_image}
		pageContext={pageContext}
	>
		<Hero
			image={translations.about_background_image}
			title={translations.about_title}
			text={translations.about_descriptions}
		/>
		<Spacer mobile={50} desktop={120} />
		<Container left={236} right={136}>
			<h2 className="h1">{translations.about_cooperations_title}</h2>
			<Spacer mobile={30} desktop={60} />
			{translations.about_cooperation_categories.map(
				(category, index) => (
					<React.Fragment key={index}>
						<NumberedContent
							number={index + 1}
							title={category.cooperation_title}
						>
							{renderRow(category.cooperation_descriptions)}
						</NumberedContent>
						{index + 1 <
							translations.about_cooperation_categories
								.length && <Spacer mobile={30} desktop={60} />}
					</React.Fragment>
				)
			)}
			<Spacer mobile={65} desktop={155} />
		</Container>
		<Background slideUp floated>
			<Container left={236} right={236}>
				<Spacer mobile={50} desktop={100} />
				<h2 className="h1">{translations.about_support_title}</h2>
				<Spacer mobile={40} desktop={60} />
				<Grid>
					<Grid.Col offset="md-1" width="md-11">
						<Margins double>
							{translations.about_support_categories
								.filter(getEven)
								.map((data, index) => (
									<TextCard
										key={index}
										title={data.support_title}
										text={data.support_description}
									/>
								))}
						</Margins>
					</Grid.Col>
					<Grid.Col width="md-11">
						<Margins double>
							{translations.about_support_categories
								.filter(getOdd)
								.map((data, index) => (
									<TextCard
										key={index}
										title={data.support_title}
										text={data.support_description}
									/>
								))}
						</Margins>
					</Grid.Col>
				</Grid>
			</Container>
		</Background>
		<Spacer mobile={50} desktop={100} />
		<Container left={236} right={236}>
			<h2 className="margins__triple h1">
				{translations.about_countries_title}
			</h2>
			<Map countries={translations.about_countries} />
		</Container>
		<Spacer mobile={70} />
	</Layout>
);

export default AboutPage;

export const query = graphql`
	query($id: Int, $lang: String) {
		wordpressPage(
			wordpress_id: { eq: $id }
			wpml_current_locale: { eq: $lang }
		) {
			wpml_current_locale
			acf {
				seo_title
				seo_description
				seo_cover_image
				about_title
				about_descriptions {
					description
				}
				about_background_image
				about_cooperations_title
				about_cooperation_categories {
					cooperation_title
					cooperation_descriptions {
						cooperations_description
					}
				}
				about_support_title
				about_support_categories {
					support_title
					support_description
				}
				about_countries_title
				about_countries {
					country_key
					country_name
					country_label
				}
			}
		}
	}
`;
